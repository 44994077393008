import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table, Typography, Space, Row, Col, Divider } from 'antd';
import EmailSender from './EmailSender';

const { Option } = Select;
const { Title } = Typography;

function Reminder() {
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [selectedCompanyYear, setSelectedCompanyYear] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [evaluatees, setEvaluatees] = useState([]);
  const [selectedEvaluatee, setSelectedEvaluatee] = useState('');
  const [evaluatorCompletion, setEvaluatorCompletion] = useState([]);
  const [evaluatorEmailsToSend, setEvaluatorEmailsToSend] = useState([]);
  const [numberOfQuestionsSelected, setNumberOfQuestionsSelected] = useState('');
  const [URL, setURL] = useState('');
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    axios.get('https://backend.idacds.systems/companiesi', {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          // Add any other headers here if needed
      }
  })
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      });
  }, []);

  useEffect(() => {
    if (companyId) {
      axios.get(`https://backend.idacds.systems/evaluatees/${companyId}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    })
        .then(response => {
          setEvaluatees(response.data);
        })
        .catch(error => {
          console.error('Error fetching evaluatees:', error);
        });
    } else {
      setEvaluatees([]);
    }
  }, [companyId]);

  const handleCompanyNameChange = (value) => {
    setSelectedCompanyName(value);
    setSelectedCompanyYear('');
    setCompanyId('');
    setSelectedEvaluatee('');
    setEvaluatorCompletion([]);
    setEvaluatorEmailsToSend([]);
    setEvaluatees([]);
  };

  const handleCompanyYearChange = (value) => {
    setSelectedCompanyYear(value);
    setSelectedEvaluatee('');
    setEvaluatorCompletion([]);
    setEvaluatorEmailsToSend([]);
    const selectedCompany = companies.find(company =>
      company.CompanyName === selectedCompanyName && company.CompanyYear === parseInt(value)
    );
    if (selectedCompany) {
      setCompanyId(selectedCompany.CompanyID);
    } else {
      setCompanyId('');
    }
  };

  const handleEvaluateeChange = (value) => {
    setSelectedEvaluatee(value);
    if (value) {
      axios.get(`https://backend.idacds.systems/completed/${value}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    })
        .then(response => {
          const evaluators = response.data || [];
          setEvaluatorCompletion(evaluators);
          setEvaluatorEmailsToSend(
            evaluators.filter(evaluator => evaluator.Completion?.toLowerCase() === "no")
                      .map(evaluator => ({ email: evaluator.EvaluatorEmail, name: evaluator.Name }))
          );
          if (evaluators.length > 0) {
            const selectedEvaluateeObj = evaluatees.find(e => e.EvaluateeID === value);
            setNumberOfQuestionsSelected(evaluators[0].NumberOfQuestions);
            setURL(`https://backend.idacds.systems/form/${value}`);
            setFullName(`${selectedEvaluateeObj.FirstName} ${selectedEvaluateeObj.LastName}`);
          }
        })
        .catch(error => {
          console.error('Error fetching evaluator completion:', error);
        });
    } else {
      setEvaluatorCompletion([]);
      setEvaluatorEmailsToSend([]);
    }
  };

  const columns = [
    {
      title: 'Evaluator Email',
      dataIndex: 'EvaluatorEmail',
      key: 'EvaluatorEmail',
    },
    {
      title: 'Completion',
      dataIndex: 'Completion',
      key: 'Completion',
    },
  ];

  const companyNames = [...new Set(companies.map(company => company.CompanyName))];
  const companyYears = selectedCompanyName ? 
    [...new Set(companies.filter(company => company.CompanyName === selectedCompanyName).map(company => company.CompanyYear))] : [];

    return (
      <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
        <Title strong>Email Reminders</Title>
        <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
          Email Reminders For Pending Questionnaires
        </Title>
        <div
          style={{
            marginBottom: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            borderRadius: '10px',
            paddingTop: '10px',
            paddingLeft: '30px',
            paddingBottom: '30px',
            paddingRight: '30px',
          }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Title level={5}>Company Name:</Title>
            </Col>
            <Col span={8}>
              <Title level={5}>Year:</Title>
            </Col>
            <Col span={8}>
              <Title level={5}>Nominee Name:</Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Select
                style={{
                  backgroundColor: '#F6F7FB',
                  borderRadius: '10px',
                  border: '1px solid #D9D9D9',
                  minHeight: '32px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
                placeholder="Select a company name"
                onChange={handleCompanyNameChange}
                value={selectedCompanyName}
              >
                {companyNames.map(name => (
                  <Option key={name} value={name}>{name}</Option>
                ))}
              </Select>
            </Col>
            <Col span={8}>
              <Select
                style={{
                  backgroundColor: '#F6F7FB',
                  borderRadius: '10px',
                  border: '1px solid #D9D9D9',
                  minHeight: '32px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
                placeholder="Select a company year"
                onChange={handleCompanyYearChange}
                value={selectedCompanyYear}
                disabled={!selectedCompanyName}
              >
                {companyYears.map(year => (
                  <Option key={year} value={year}>{year}</Option>
                ))}
              </Select>
            </Col>
            <Col span={8}>
              <Select
                style={{
                  backgroundColor: '#F6F7FB',
                  borderRadius: '10px',
                  border: '1px solid #D9D9D9',
                  minHeight: '32px',
                  fontWeight: 'bold',
                  width: '100%',
                }}
                placeholder="Select an evaluatee"
                onChange={handleEvaluateeChange}
                value={selectedEvaluatee}
                disabled={!companyId}
              >
                {evaluatees.map(evaluatee => (
                  <Option key={evaluatee.EvaluateeID} value={evaluatee.EvaluateeID}>
                    {evaluatee.FirstName} {evaluatee.LastName}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        
        <Row gutter={16} style={{ marginTop: '20px' }}>
          {selectedEvaluatee && (
            <>
              <Col span={12}>
                <div style={{
            marginBottom: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            borderRadius: '10px',
            paddingTop: '10px',
            paddingLeft: '30px',
            paddingBottom: '30px',
            paddingRight: '30px',
            minHeight: '560px',
          }}>
                  <Title level={5}>Evaluation Progress</Title>
                  <Table
                    dataSource={evaluatorCompletion}
                    columns={columns}
                    rowKey="EvaluatorEmail"
                    components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
                  />
                </div>
              </Col>
              <Col span={12}>
                {evaluatorEmailsToSend.length > 0 && (
                  <div style={{
            marginBottom: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            borderRadius: '10px',
            paddingTop: '10px',
            paddingLeft: '30px',
            paddingBottom: '30px',
            paddingRight: '30px',
            minHeight: '560px',
          }}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <EmailSender
                        key={selectedEvaluatee}
                        evaluators={evaluatorEmailsToSend}
                        companyName={companies.find(company => company.CompanyID === companyId)?.CompanyName || ''}
                        numberOfQuestionsSelected={numberOfQuestionsSelected}
                        fullName={fullName}
                        URL={URL}
                      />
                    </Space>
                  </div>
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
    );
  }
  
  export default Reminder;