import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table, Spin, Row, Col, Typography, Divider, Button } from 'antd';
import { saveAs } from 'file-saver';

const { Option } = Select;
const { Title } = Typography;

const Report = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedCompanyYear, setSelectedCompanyYear] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [evaluatees, setEvaluatees] = useState([]);
    const [selectedEvaluatee, setSelectedEvaluatee] = useState('');
    const [report, setReport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeReport, setActiveReport] = useState('');

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('https://backend.idacds.systems/companiesi', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchEvaluateesForCompany = async (companyId) => {
        try {
            const response = await axios.get(`https://backend.idacds.systems/evaluatees/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setEvaluatees(response.data);
        } catch (error) {
            console.error('Error fetching evaluatees:', error);
        }
    };

    const fetchReport = async () => {
        console.log(selectedEvaluatee)
        setLoading(true);
        try {
            const response = await axios.get(`https://backend.idacds.systems/evaluatee-report/${selectedEvaluatee}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setReport(response.data);
            setActiveReport('person');
        } catch (error) {
            console.error('Error fetching report:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCompanyNameChange = (value) => {
        setSelectedCompanyName(value);
        setSelectedCompanyYear('');
        setSelectedCompanyId('');
        setEvaluatees([]);
        setSelectedEvaluatee('');
        setReport([]);
        setActiveReport('');
    };

    const handleCompanyYearChange = (value) => {
        setSelectedCompanyYear(value);
        const selectedCompany = companies.find(company =>
            company.CompanyName === selectedCompanyName && company.CompanyYear === parseInt(value)
        );
        setSelectedCompanyId(selectedCompany ? selectedCompany.CompanyID : '');
        if (selectedCompany) {
            fetchEvaluateesForCompany(selectedCompany.CompanyID);
        }
    };

    const handleEvaluateeSelection = (value) => {
        setSelectedEvaluatee(value);
    };

    useEffect(() => {
        if (selectedCompanyId && selectedEvaluatee) {
            fetchReport();
        }
    }, [selectedCompanyId, selectedEvaluatee]);

    const resetPersonReportSelection = () => {
        setSelectedCompanyName('');
        setSelectedCompanyYear('');
        setSelectedCompanyId('');
        setEvaluatees([]);
        setSelectedEvaluatee('');
        setReport([]);
    };

    const reportColumns = [
        {
            title: 'Evaluatee',
            dataIndex: 'Evaluatee',
            key: 'Evaluatee',
            render: (text, record, index) => {
                const currentEvaluatee = report[index];
                const previousEvaluatee = report[index - 1];
                const isSameEvaluatee = previousEvaluatee && previousEvaluatee.Evaluatee === currentEvaluatee.Evaluatee;
                const rowSpan = isSameEvaluatee ? 0 : report.filter(item => item.Evaluatee === currentEvaluatee.Evaluatee).length;
                return {
                    children: text,
                    props: { rowSpan }
                };
            }
        },
        { title: 'Question', dataIndex: 'Question', key: 'Question' },
        {
            title: 'Category',
            dataIndex: 'QuestionCategory',
            key: 'QuestionCategory',
            render: (text, record, index) => {
                const currentCategory = report[index];
                const previousCategory = report[index - 1];
                const isSameCategory = previousCategory && previousCategory.QuestionCategory === currentCategory.QuestionCategory;
                const rowSpan = isSameCategory ? 0 : report.filter(item => item.QuestionCategory === currentCategory.QuestionCategory).length;
                return {
                    children: text,
                    props: { rowSpan }
                };
            }
        },
        { title: 'Strongly Disagree', dataIndex: 'StronglyDisagree', key: 'StronglyDisagree' },
        { title: 'Disagree', dataIndex: 'Disagree', key: 'Disagree' },
        { title: 'Neither Agree nor Disagree', dataIndex: 'NeitherAgreeNorDisagree', key: 'NeitherAgreeNorDisagree' },
        { title: 'Agree', dataIndex: 'Agree', key: 'Agree' },
        { title: 'Strongly Agree', dataIndex: 'StronglyAgree', key: 'StronglyAgree' },
        { title: 'Total Question Value', dataIndex: 'TotalQuestionValue', key: 'TotalQuestionValue' },
        { title: 'Average', dataIndex: 'Average', key: 'Average' },
    ];

    const preprocessDataForCSV = (data) => {
        if (data.length === 0) return data;
        let lastEvaluatee = '';
        let lastCategory = '';
        return data.map(row => {
            const newRow = { ...row };
            if (newRow.Evaluatee === lastEvaluatee) {
                newRow.Evaluatee = '';
            } else {
                lastEvaluatee = newRow.Evaluatee;
            }
            if (newRow.QuestionCategory === lastCategory) {
                newRow.QuestionCategory = '';
            } else {
                lastCategory = newRow.QuestionCategory;
            }
            return newRow;
        });
    };

    const exportToCSV = (data, filename) => {
        if (data.length === 0) return;
        const headers = Object.keys(data[0]).join(',');
        const csvContent = data.map(row => Object.values(row).map(value => `"${value}"`).join(',')).join('\n');
        const blob = new Blob([`${headers}\n${csvContent}`], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${filename}.csv`);
    };

    return (
        <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
            <Title strong>Personalized Report</Title>
            <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
                Analyze Personalized Data And Insights
            </Title>
            <Row gutter={[24]}>
                <Col span={24}>
                    <div style={{
                        marginBottom: '20px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        borderRadius: '10px',
                        padding: '10px 30px',
                        paddingBottom: '30px'
                    }}>
                    <Row gutter={16}>
                                <Col span={8}>
                                <Title level={5}>Company Name:</Title>
                                </Col>
                                <Col span={8}>
                                <Title level={5}>Year:</Title>
                                </Col>
                                <Col span={8}>
                                <Title level={5}>Nominee Name:</Title>
                                </Col>
                                </Row>
                        <Row gutter={[24]}>
                            <Col span={8}>
                                <Select
                        
                                    placeholder="Select a company name"
                                    onChange={handleCompanyNameChange}
                                    value={selectedCompanyName}
                                    style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                                >
                                    {Array.from(new Set(companies.map(company => company.CompanyName))).map(name => (
                                        <Option key={name} value={name}>{name}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={8}>
                                {selectedCompanyName && (
                                    <Select
                                        placeholder="Select a company year"
                                        onChange={handleCompanyYearChange}
                                        value={selectedCompanyYear}
                                        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                                    >
                                        {Array.from(new Set(companies
                                            .filter(company => company.CompanyName === selectedCompanyName)
                                            .map(company => company.CompanyYear)))
                                            .map(year => (
                                                <Option key={year} value={year}>{year}</Option>
                                            ))}
                                    </Select>
                                )}
                            </Col>
                            <Col span={8}>
                                {selectedCompanyId && (
                                    <Select
                                        placeholder="Select an evaluatee"
                                        onChange={handleEvaluateeSelection}
                                        value={selectedEvaluatee}
                                        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                                    >
                                        {evaluatees.map(evaluatee => (
                                            <Option key={evaluatee.EvaluateeID} value={evaluatee.EvaluateeID}>
                                                {evaluatee.FirstName} {evaluatee.LastName}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {loading ? (
                <Spin style={{ marginTop: '20px' }} />
            ) : (
                activeReport === 'person' && report.length > 0 && (
                    
                    <div style={{
                        marginBottom: '20px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        borderRadius: '10px',
                        padding: '10px 30px',
                        paddingBottom: '30px'
                    }}>
                    <Button
                            type="primary"
                            style={{ 
                                marginTop: '20px',
                                float: 'right',
                                marginBottom: '20px',
                                backgroundColor:'#3B7DDE',
                                 }}
                            onClick={() => exportToCSV(preprocessDataForCSV(report), 'Person Report')}
                        >
                            Export as CSV
                        </Button>
                        <Table
                            columns={reportColumns}
                            dataSource={report}
                            rowKey={(record, index) => index}
                            pagination={false}
                            components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
                        />
                        
                    </div>
                )
            )}
        </div>
    );
};

export default Report;
