import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, message, Tag, DatePicker, Row, Col, Select } from 'antd';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import emailTemplate from './emailtext';
import emailTemplate2 from './emailtext2';
import emailTemplate3 from './emailtext3';

const { Title } = Typography;
const { Option } = Select;

const EmailSender = ({ evaluators: initialEvaluators = [], companyName, numberOfQuestionsSelected, fullName, URL }) => {
    const [form] = Form.useForm();
    const [sending, setSending] = useState(false);
    const [emailBody, setEmailBody] = useState('');
    const [evaluators, setEvaluators] = useState(initialEvaluators);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [emailSource, setEmailSource] = useState('gma'); // Default to GMA

    // Initialize emailBody with processed template
    useEffect(() => {
        const processedBody = emailTemplate.replace('[URL]', URL);
        setEmailBody(processedBody);
        form.setFieldsValue({ body: processedBody });
    }, [URL]);

    useEffect(() => {
        form.setFieldsValue({ to: evaluators.map(e => e.email).join(', ') });
    }, [evaluators]);

    useEffect(() => {
        let template;
        if (selectedTemplate === 'template1') {
            template = emailTemplate;
            setEmailSource('cla'); // EVP uses GMA email
        } else if (selectedTemplate === 'template2') {
            template = emailTemplate2;
            setEmailSource('gma'); // Agile Leadership uses CLA email
        } else if (selectedTemplate === 'template3') {
            template = emailTemplate3;
            setEmailSource('gma'); // Great Managers uses GMA email
        }
        
        if (template) {
            const processedTemplate = template.replace('[URL]', URL);
            setEmailBody(processedTemplate);
            form.setFieldsValue({ body: processedTemplate });
        }
    }, [selectedTemplate, URL]);

    const handleSubmit = async (values) => {
        setSending(true);
        try {
            console.log('Company Name:', companyName);
            console.log('Number of Questions Selected:', numberOfQuestionsSelected);
            console.log('Full Name:', fullName);
            console.log('FromDate:', fromDate);
            console.log('ToDate:', toDate);
            console.log('URL:', URL);
            console.log('Evaluators:', evaluators);
            console.log('Email Source:', emailSource);

            await axios.post('http://localhost:5000/send-emails', {
                subject: values.subject,
                body: emailBody,
                evaluators: evaluators,
                companyName,
                numberOfQuestionsSelected,
                fullName,
                fromDate: fromDate,
                toDate: toDate,
                emailSource: emailSource // Add the email source to the request
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });

            message.success('Email sent successfully!');
            form.resetFields();
        } catch (error) {
            console.error('Error sending email:', error);
            message.error(error.response?.data?.message || 'Failed to send email');
        } finally {
            setSending(false);
        }
    };

    const handleRemoveEmail = (emailToRemove) => {
        const updatedEvaluators = evaluators.filter(evaluator => evaluator.email !== emailToRemove);
        setEvaluators(updatedEvaluators);
    };

    // Get email source display text
    const getEmailSourceText = () => {
        return emailSource === 'gma' ? 'Great Manager Awards' : 'CLA Coaching';
    };

    return (
        <div>
            <Title level={5} style={{ paddingBottom: '10px' }}>Email Sender</Title>
            <Form.Item style={{ marginBottom: 0 }}>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Dates From"
                            name="fromDate"
                            rules={[{ required: true, message: 'Please select the From date' }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <DatePicker 
                                format="YYYY-MM-DD" 
                                style={{ width: '100%' }}
                                onChange={(date, dateString) => setFromDate(dateString)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Dates To"
                            name="ToDate"
                            rules={[{ required: true, message: 'Please select the To date' }]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <DatePicker 
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                                onChange={(date, dateString) => setToDate(dateString)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item
                    label="Sending From"
                    style={{ marginBottom: '12px' }}
                >
                    <Tag color={emailSource === 'gma' ? 'blue' : 'green'}>
                        {getEmailSourceText()}
                    </Tag>
                </Form.Item>

                <Form.Item
                    label="To"
                    name="to"
                    rules={[{ message: 'Please enter recipient email' }]}
                >
                    {evaluators.map((evaluator) => (
                        <Tag
                            key={evaluator.email}
                            style={{ borderRadius: '25px', margin: '5px', display: 'inline-flex', alignItems: 'center' }}
                        >
                            {evaluator.email}
                            <Button
                                type="text"
                                size="small"
                                icon={<CloseOutlined />}
                                onClick={() => handleRemoveEmail(evaluator.email)}
                                style={{ marginLeft: '5px', padding: 0 }}
                            />
                        </Tag>
                    ))}
                </Form.Item>

                <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: 'Please enter email subject' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Template"
                    name="body"
                    rules={[{ required: true, message: 'Please select an email template' }]}
                >
                    <Select 
                        onChange={setSelectedTemplate} 
                        placeholder="Select an email template"
                    >
                        <Option value="template1">EVP (CLA)</Option>
                        <Option value="template2">Agile Leadership Awards (GMA)</Option>
                        <Option value="template3">Great Managers (GMA)</Option>
                    </Select>
                </Form.Item>
                
                <Form.Item>
                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        loading={sending} 
                        icon={<SendOutlined />} 
                        style={{ backgroundColor: '#3b7dde' }}
                    >
                        Send Email
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EmailSender;